import Vue from "vue";
import VueRouter from "vue-router";
import ROUTE from "~/constants/routes";
import i18n from "~/plugins/i18n";

const Home = () => import("~/pages/Home.vue");
const Project = () => import("~/pages/project/index.vue");
const ProjectRooms = () => import("~/pages/project/rooms.vue");
const Orders = () => import("~/pages/order/index.vue");
const OrdersSuccess = () => import("~/pages/order/success.vue");

const PageNotFound = () => import("~/pages/error/PageNotFound.vue");

Vue.use(VueRouter);

const lang = (to, from, next) => {
  const validLanguages = ["de"]; // Разрешенные языки
  if (validLanguages.includes(to.params.lang)) {
    i18n.locale = to.params.lang;
    next();
  } else {
    next({ name: "PageNotFound" });
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: (to, from, next) => {
      i18n.locale = "en";
      next();
    },
  },
  {
    path: "/:lang",
    name: "HomeLang",
    component: Home,
    beforeEnter: (to, from, next) => lang(to, from, next),
  },

  {
    path: ROUTE.PROJECT,
    name: "Project",
    component: Project,
    beforeEnter: (to, from, next) => {
      i18n.locale = "en";
      next();
    },
  },
  {
    path: "/:lang" + ROUTE.PROJECT,
    name: "Project",
    component: Project,
    beforeEnter: (to, from, next) => lang(to, from, next),
  },

  {
    path: ROUTE.PROJECT_ROOMS,
    name: "ProjectRooms",
    component: ProjectRooms,
    beforeEnter: (to, from, next) => {
      i18n.locale = "en";
      next();
    },
  },
  {
    path: "/:lang" + ROUTE.PROJECT,
    name: "ProjectRooms",
    component: ProjectRooms,
    beforeEnter: (to, from, next) => lang(to, from, next),
  },

  {
    path: ROUTE.ORDERS,
    name: "Orders",
    component: Orders,
    beforeEnter: (to, from, next) => {
      i18n.locale = "en";
      next();
    },
  },
  {
    path: "/:lang" + ROUTE.ORDERS,
    name: "Orders",
    component: OrdersSuccess,
    beforeEnter: (to, from, next) => lang(to, from, next),
  },

  {
    path: ROUTE.ORDERS_SUCCESS,
    name: "OrdersSuccess",
    component: OrdersSuccess,
    beforeEnter: (to, from, next) => {
      i18n.locale = "en";
      next();
    },
  },
  {
    path: "/:lang" + ROUTE.ORDERS_SUCCESS,
    name: "OrdersSuccess",
    component: Orders,
    beforeEnter: (to, from, next) => lang(to, from, next),
  },

  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound, // Добавьте сюда компонент 404
  },
];

const router = new VueRouter({
  mode: "history",

  base: process.env.BASE_URL,

  routes,
});

export default router;
